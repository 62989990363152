import ticket from './img/golden_ticket.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={ticket} className="App-logo" alt="logo" />
        <p/>
        <p/>
        <p>
          SCOR3-TIX
        </p>
      </header>
    </div>
  );
}

export default App;
